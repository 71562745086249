.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use full viewport height to center vertically */
  background-color: #f7f8fa;
  padding: 10px 20px;
}

.loginForm {
  padding: 2em;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Set a max-width for the form */
  width: 100%;
  box-sizing: border-box;
}

.passwordContainer {
  position: relative;
}

.togglePasswordButton {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  background-color: transparent;
  height: fit-content;
  cursor: pointer;
}

.togglePasswordButton:hover,
.togglePasswordButton:focus {
  background-color: transparent !important;
}

/* Header styles */
.adminHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  color: #fff;
  position: relative;
  z-index: 1000;
  padding: 0 15rem;
}

.adminTitle {
  flex: 1;
  font-size: 24px;
}

.hamburgerButton {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
}

.adminNav {
  position: relative;
  right: 0;
  background: white;
  color: black;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out; /* Smooth transition for max-height */
  max-height: 0; /* Start with max-height 0 for a collapsed state */
  z-index: 999;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.adminNavOpen {
  max-height: 300px; /* Max height for expanded menu */
}

.adminNav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.adminNav li a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  padding: 20px 0;
  border-bottom: 1px solid #e9e9e9;
  text-align: center;
}

.adminNav li a:hover {
  background: #f0f0f0;
}

.show-mbti-results-container {
  padding: 10px;
  cursor: pointer;
  flex-direction: column;
  border: 1px #ccc solid;
  border-radius: 5px;
  padding: 1rem;
}

@media(max-width: 768px) {
  .adminHeader {
    padding: 0 1rem;
  }
}