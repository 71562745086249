@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

:root {
  --font-family-main: 'Open Sans';
  --font-family-title: 'Bebas Neue';
  --font-size: 1rem;
}

.certificate-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: auto;
  width: 1028px;
  height: 720px;
  font-family: 'Open Sans';
}

.left-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: #004AAD;
  color: white;
  padding: 3rem;
  position: relative;
  gap: 1.5rem;
}

.left-header {
  font-weight: bold;
  letter-spacing: 0.25rem;
  font-size: smaller;
}

.student-name {
  font-family: var(--font-family-title);
  font-size: 4rem;
  letter-spacing: 0.125rem;
  text-align: left;
  line-height: 3.5rem;
}

.right-container {
  padding: 2.5rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.scores-container {
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  grid-gap: 5rem;
}

.score-item-char {
  font-family: var(--font-family-title);
  font-size: 3rem;
  text-align: center;
  position: relative;
}

.score-item-char::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.8rem;
  width: 100%;
  height: 0.8rem;
  background-color: #FFE23E;
}

.score-item-scores {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
}

.company-logo {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 5rem;
  height: auto;
}

.mbti-certified-logo {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: auto;
}

.assessment-date {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  text-align: left;
  font-size: 0.8rem;
}

.certificate-preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.certificate-preview-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.certificate-preview-container a {
  color: white;
}

.certificate-preview-overlay-menu {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: white;
}

.certificate-preview-overlay-footer {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  padding: 0.5rem 1rem;
}

.overlay-icon-button {
  font-size: 2rem;
  cursor: pointer;
}

.overlay-icon-button.disabled {
  color: grey;
  pointer-events: none;
}

.overlay-button {
  border: 2px white solid;
  background-color: transparent !important;
}

@media (max-width: 768px) {
  .certificate-container {
    width: 380px;
    height: 285px;
  }

  .certificate-preview-container {
    gap: 0.25rem;
  }

  .left-container {
    padding: 1rem;
    gap: 1rem;
  }

  .left-header {
    letter-spacing: 0.125em;
    font-size: 0.35rem;
  }

  .student-name {
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    line-height: 1.5rem;
  }

  .right-container {
    padding: 1.5rem;
    gap: 1.5rem;
  }
  
  .scores-container {
    grid-gap: 1.5rem;
  }

  .score-item-char {
    font-size: 1.25rem;
  }
  
  .score-item-char::after {
    bottom: -0.25rem;
    height: 0.25rem;
  }

  .score-item-scores {
    gap: 0.25rem;
    font-size: 0.6rem;
  }

  .company-logo {
    top: 0.5rem;
    left: 0.5rem;
    width: 2rem;
  }

  .mbti-certified-logo {
    bottom: 0.5rem;
    right: 0.5rem;
    width: 1.2rem;
  }

  .assessment-date {
    bottom: 0.5rem;
    left: 0.5rem;
    font-size: 0.3rem;
  }

  .overlay-icon-button {
    font-size: 1.5rem;
  }
}