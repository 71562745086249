.slideContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will space out the child elements */
  height: 100%; /* Make sure this is 100% of the Slider's height */
  margin-top: 10%;
}

.questionCounter {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 10px; /* Adjust as needed */
}

.questionText {
  text-align: center;
  font-size: 1.25rem;
  flex-grow: 1; /* This will allow the question text to take up available space */
  display: flex;
  align-items: center; /* This vertically centers the text in the available space */
  justify-content: center;
  flex-direction: column;
}

.translatedQuestionText {
  text-align: center;
  font-size: smaller;
  flex-grow: 1; /* This will allow the question text to take up available space */
  display: flex;
  align-items: center; /* This vertically centers the text in the available space */
  justify-content: center;
  padding-bottom: 1em;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notlikeme {
  background-color: transparent;
  color: #b40000;
  border: 2px solid #b40000;
}

.notlikeme:hover,
.notlikeme:focus,
.notlikeme:active {
  background-color: #b40000;
  color: #fff;
}

.somewhatlikeme {
  background-color: transparent;
  color: orange;
  border: 2px solid orange;
}

.somewhatlikeme:hover,
.somewhatlikeme:focus,
.somewhatlikeme:active {
  background-color: orange;
  color: white;
}

.exactlylikeme {
  background-color: transparent;
  color: green;
  border: 2px solid green;
}

.exactlylikeme:hover,
.exactlylikeme:focus,
.exactlylikeme:active {
  background-color: green;
  color: white;
}

.answerButton {
  /* common button styles */
  transition: background-color 0.3s ease;
}

.answerButton:disabled {
  border-color: #c9c9c9;
  color: #c9c9c9;
  background-color: transparent;
}

.answerButton.notlikeme.selected {
  background-color: #b40000;
  color: #fff;
}

.answerButton.somewhatlikeme.selected {
  background-color: orange;
  color: white;
}

.answerButton.exactlylikeme.selected {
  background-color: green;
  color: white;
}

/* Non-selected button styles, for when another option has been selected */
.answerButton.notlikeme:not(.selected) {
  /* styles for not selected */
}

.answerButton.somewhatlikeme:not(.selected) {
  /* styles for not selected */
}

.answerButton.exactlylikeme:not(.selected) {
  /* styles for not selected */
}

/* Below effectively overwrites slick-slider's height */
.slick-slider {
  /* height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)); */
  height: calc(var(--vh, 1vh) * 100);
}

.slick-dots {
  position: absolute;
  bottom: 25px; /* Adjust this value as necessary to position the dots */
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.slick-prev,
.slick-next,
.slick-prev:focus,
.slick-next:focus {
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 1rem;
  height: 100vh;
  z-index: 100;
}

.slick-prev:hover,
.slick-next:hover {
  background: rgba(0, 0, 0, 0.25);
}