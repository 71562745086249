.scoresContainer {
  display: flex;
  flex-direction: column;
}

.dichotomyContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
}

.attributeContainer {
  flex: 1;
}

.rangeContainer {
  flex: 2;
  padding: 0 5px;
}

.resultRange {
  width: 100%;
}

.score {
  font-size: normal;
}

.translatedAttribute {
  font-size: small;
}