* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #f7f8fa;
  color: #333;
  font-size: 1rem;
}

.mainContainer {
  padding: 10px 20px;
  margin-bottom: 3rem;
  max-width: 472px;
  margin: auto;
}

.formGroup {
  margin-bottom: 1em;
}

.label {
  display: block;
  margin-bottom: 0.5em;
}

.input {
  width: 100%;
  padding: 0.75em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  padding: 0.75em;
  border: none;
  border-radius: 4px;
  background-color: #0056b3;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover,
button:focus {
  background-color: #003d82;
}

.errorMessage {
  color: red;
}

.accordion-toggle {
  width: 100%;
  text-align: left;
  padding: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 5px 0;
  color: black;
  border: 1px #ccc solid;
  padding: 10px 10px;
}

.accordion-toggle:hover,
.accordion-toggle:focus,
.accordion-content.open {
  background-color: transparent;
}

.accordion-content {
  max-height: 0;
  background-color: #f9f9f9;
  padding: 0 10px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.accordion-content.open {
  max-height: 1000px; /* Adjust if needed */
  padding: 20px 10px 30px 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

input,
select {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  width: 100%;
  box-sizing: border-box;
}

.trainingSessionsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trainingSessionsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.trainingSessionsItemOptionsContainer {
  display: flex;
  flex-direction: column;
}

/* Toggle Switch */
.switch-container {
  display: flex;
  align-items: center; /* Aligns the switch and label vertically */
  gap: 8px; /* Adjust the space between switch and label */
}

.switch-label {
  font-size: smaller;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2.2rem;
  height: 1.25rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0.1rem;
  bottom: 0.1rem;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0056b3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #003d82;
}

input:checked + .slider:before {
  transform: translateX(1rem);
}

.flex-gap,
.flex-gap-column {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.flex-gap-column {
  flex-direction: column;
}

/* Icon button */
.icon-button {
  color: #0056b3; /* Bootstrap primary color for consistency */
  cursor: pointer;
  font-size: 1.5rem; /* Icon size */
}

.icon-button:hover {
  color: #003d82; /* Darker shade on hover */
}

/* Mobile responsive style for .button */
@media (max-width: 768px) {
  button {
    width: 100%;
  }

  .flex-gap {
    flex-direction: column;
    gap: 0.5rem;
  }
}