/* src/styles/ViewResults */
.student-results-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.student-result-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px #ccc solid;
  border-radius: 0.25rem;
  padding: 1.5rem 1rem;
}